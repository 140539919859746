import React from "react"
import NewLayout from "../components/new_layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk"

const CustomerHubAddOns = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page
    const principal = page.items.slice(0, 4)
    const secondary = page.items.slice(4, 7)
    const tertiary = page.items.slice(7, 11)

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext?.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
            />

            <main className="main main--customer-hub-addons">
                <div className="main__section main__section--100 white-section brown">
                    <div className="container" data-aos="fade-up">
                        <h1 lang={lang} className="merriweather">{page.title}</h1>
                        <div className="container__text">
                            <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <div className="grid-lg-2">
                            {principal.map((item, index) => (
                                <div className="image-group" key={index}>
                                    <div>
                                        <GatsbyImage alt={item.image.alternativeText} image={getImage(item.image.localFile)} />
                                    </div>
                                    <div className="image-group__text">
                                        <h5>{item.title}</h5>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="grid-lg-3-h">
                            {secondary.map((item, index) => (
                                <div className="image-group" key={index}>
                                    <div>
                                        <GatsbyImage alt={item.image.alternativeText} image={getImage(item.image.localFile)} />
                                    </div>
                                    <div className="image-group__text">
                                        <h5>{item.title}</h5>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="grid-lg-4">
                            {tertiary.map((item, index) => (
                                <div className="image-group" key={index}>
                                    <div>
                                        <GatsbyImage alt={item.image.alternativeText} image={getImage(item.image.localFile)} />
                                    </div>
                                    <div className="image-group__text">
                                        <h5>{item.title}</h5>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <ReadyToTalk lang={lang} />
            </main>
        </NewLayout>
    )
}

export default CustomerHubAddOns

export const customerHubAddOnsQuery = graphql`
    query($langKey: String) {
        page: strapi2023ChAddOns( locale: { eq: $langKey } ) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL
                    }
                }
            }
            title
            description
            items {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 912
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
        }
    }
`
